@tailwind base;
@tailwind components;
@tailwind utilities;

.main-page {
    background-image: url('./assets/Background_Image.png');
}

@font-face {
    font-family: 'courier';
    src: url('../public/FONTS/courier-font/cour.ttf');
}

@font-face {
    font-family: 'courier';
    src: url('../public/FONTS/courier-font/courbd.ttf');
    font-weight: bold;
}

@font-face {
    font-family: 'courier';
    src: url('../public/FONTS/courier-font/courbi.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'courier';
    src: url('../public/FONTS/courier-font/couri.ttf');
    font-style: italic;
}

@font-face {
    font-family: 'dragonslapper';
    src: url('../public/FONTS/dragonslapper-font/DragonslapperRegular.ttf');
}